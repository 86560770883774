import { useLocation } from "react-router-dom";

const BREADCRUMB_CONFIG = {
  // Dashboard & Search
  "/dashboard": [["Dashboard"]],
  "/search(/\\d*)?": [["Pesquisar"]],

  // Tracking
  "/tracking": [["Car Tracking"]],
  "/tracking/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/[A-z]{3}\\d(\\d|[A-z])\\d{2}": [
    ["Car Tracking", "/tracking"],
    ["Detalhes"],
  ],

  // Clientes
  "/clientes/busca": [["Clientes"], ["Buscar"]],
  "/clientes/uploads": [["Clientes", "/clientes/busca"], ["Uploads"]],

  // Avaliações
  "/avaliacoes": [["Avaliações"], ["Realizadas"]],
  "/avaliacoes/pendentes": [["Avaliações"], ["Pendentes"]],
  "/avaliacoes/[^/]+/editar": [["Avaliações", "/avaliacoes"], ["Editar"]],
  "/avaliacoes/uploads": [
    ["Avaliações"],
    ["Realizadas", "/avaliacoes"],
    ["Uploads"],
  ],

  // Target Lists
  "/targetList": [["Target Lists"]],
  "/qualidade": [["Target Lists", "/targetList"], ["Qualidade"]],

  // Uploader
  "/uploader/uploads": [["Uploader"], ["Lotes"]],
  "/uploader/qualidade": [["Uploader", "/uploader/uploads"], ["Qualidade"]],

  // Integrada
  "/integrada": [["Consulta Integrada"]],

  // Dublê
  "/duble/suspeita/qualidade": [
    ["Veículo Dublê"],
    ["Validação de Suspeitas", "/duble/suspeita"],
    ["Qualidade"],
  ],
  "/duble/suspeita": [["Veículo Dublê"], ["Validação de Suspeitas"]],
  "/duble/qualidade": [["Veículo Dublê"], ["Qualidade"]],

  // Tracker
  "/tracker": [["Monitoramento"], ["Listas"]],
  "/tracker/qualidade": [["Monitoramento", "/tracker"], ["Qualidade"]],
  "/tracker/contatos": [["Monitoramento", "/tracker"], ["Contatos"]],
  "/tracker/webhooks": [["Monitoramento", "/tracker"], ["Webhooks"]],
  "/tracker/webhooks/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/editar": [
    ["Monitoramento", "/tracker"],
    ["Webhooks", "/tracker/webhooks"],
    ["Edição"],
  ],
  "/tracker/webhooks/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/regras/d+/editar": [
    ["Monitoramento", "/tracker"],
    ["Webhooks", "/tracker/webhooks"],
    ["Regras de envio", "/tracker/webhooks/regras"],
    ["Edição"],
  ],
  "/tracker/webhooks/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/regras": [
    ["Monitoramento", "/tracker"],
    ["Webhooks", "/tracker/webhooks"],
    ["Regras de envio"],
  ],
  "/tracker/detalhes/(\\w{3}\\d(\\d|\\w)\\d{2})": [
    ["Monitoramento", "/tracker"],
    ["Detalhes"],
  ],

  // Dossiês
  "/dossie": [["Dossiês"]],
  "/dossie/\\d+": [["Dossiês", "/dossie"], ["Placas"]],

  // Quali API
  "/quali-api/uploads": [["Quali API"], ["Uploads"]],
  "/quali-api/qualidade": [["Quali API", "/quali-api/uploads"], ["Qualidade"]],
  "/quali-api/overview/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})/detalhes/[A-z]{3}\\d(\\d|[A-z])\\d{2}": [
    ["Quali API", "/quali-api/overview/:overviewUuid"],
    ["Overview", "/quali-api/overview/:overviewUuid"],
    ["Detalhes"],
  ],
  "/quali-api/overview/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})": [
    ["Quali API"],
    ["Overview"],
  ],
  "/quali-api/uploads/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})": [
    ["Quali API", "/quali-api/uploads"],
    ["Uploads", "/quali-api/uploads"],
    ["Detalhes"],
  ],

  // Cameras
  "/cameras/novo": [["Câmeras", "/cameras"], ["Novo"]],
  "/cameras/[^/]+/editar": [["Câmeras", "/cameras"], ["Editar"]],
  "/cameras/monitor": [["Câmeras", "/cameras"], ["Monitoramento"]],
  "/cameras": [["Câmeras"]],

  // Fornecedores
  "/fornecedores/[^/]+/editar": [["Fornecedores"], ["Editar"]],
  "/fornecedores": [["Fornecedores"]],

  // Empresas
  "/empresas/novo": [["Empresas", "/empresas"], ["Novo"]],
  "/empresas/[^/]+/editar": [["Empresas", "/empresas"], ["Editar"]],
  "/empresas": [["Empresas"]],

  // Usuários
  "/usuarios": [["Usuários"]],
  "/usuarios/[^/]+/editar": [["Usuários", "/usuarios"], ["Editar"]],
  "/usuarios/uploads": [["Usuários", "/usuarios"], ["Uploads"]],
  "/usuarios/novo": [["Usuários", "/usuarios"], ["Novo"]],

  // Solicitações de Acesso
  "/solicitacoes": [["Solicitações de Acesso"]],
  "/solicitacoes/.+": [
    ["Solicitações de Acesso", "/solicitacoes"],
    ["Aprovação"],
  ],
};

export function useBreadCrumbLevels() {
  const location = useLocation();
  const overviewUuid =
    location.pathname.match(
      /[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}/g
    ) ||
    [][0] ||
    null;
  const matchingRoute = findMatchingRoute(location.pathname);

  return convertToBreadcrumbInfo(matchingRoute, overviewUuid);
}

// Encontrar a rota correspondente
function findMatchingRoute(pathname) {
  return (
    Object.entries(BREADCRUMB_CONFIG).find(([pattern]) => {
      const regexPattern = new RegExp(`^${pattern}$`); // Adicionando ^ e $ para garantir que a string inteira seja correspondida
      return pathname.match(regexPattern);
    })?.[1] || []
  );
}

function convertToBreadcrumbInfo(routeConfig, overviewUuid) {
  return routeConfig.map(([title, link = "#"]) => {
    const finalLink = link?.includes(":overviewUuid")
      ? link.replace(":overviewUuid", overviewUuid)
      : link;
    return { title, link: finalLink };
  });
}
